<template>

<div class="d-flex align-items-center col-actions">
  <div v-if="data.status === 'succeeded' || data.status === 'failed'">

    <div v-if="data.os === 'android'">
      <a target="_blank" class="mr-1" v-if="data.appetize_key"
        :href="`https://appetize.io/app/${data.appetize_key}?device=pixel4&scale=100&orientation=portrait&osVersion=12.0&autoplay=true&grantPermissions=true&debug=true`"
        v-b-tooltip.hover.top="'Try Game'">
        <feather-icon icon="PlayIcon" size="18" />
      </a>
    </div>
  </div>

</div>
</template>

<script>
import {
  BCard, BLink,BImg,
  BBadge,VBTooltip,BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
  name:'ActionItem',
  components: {
    BCard,
    BBadge,
    BImg,
    BAlert,
    BLink,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: ['data','viewType'],
  methods:{
   
  }
}
</script>

<style lang="scss" scoped>

</style>
